<template>
  <div>
<div :style="{marginTop: '15px'}" class="page-header">
      <v-container>
        <v-row no-gutters>
          <v-col cols="5" >
            <div class="title-panel-left">
              <router-link to="/" class="page-link">Calculator</router-link>
              <router-link to="/howto" class="page-link">How-To</router-link>
            </div>
          </v-col>
          <v-col cols="7" >
            <h1 class="page-title">Method Summary</h1>
          </v-col>
         </v-row>
      </v-container>
    </div>
    <div class="Introduction">
      <div class="welcome">Page title, explanation what is it</div>
      - Give link to the paper pdf <br>
      - Give a quick explantion of the major components: Risk, Alpha and its interpetation, Modelling and Results
    </div>
  </div>
</template>

<script>
export default {
  name: 'MethodSummary',
  // created() {
  //   if (!this.$store.state.isAuthenticated) {
  //     this.$router.push('/login');
  //   }
  // }
};
</script>

<style>
.title-panel-left {
  text-align: right;
  font-size: 2em;
  border-right: 1px solid #c4c8cc;
}
.page-header {
  min-width: 1070px!important;
}
.page-title {
    letter-spacing: 7px;
    font-weight: lighter;
}
.page-link {
    letter-spacing: 4px; /* Adjust the value as needed */
    margin-top: 40px!important;
    margin-bottom: 10px;
    font-weight: lighter;
    font-size: 22px!important;
    color: #006AC3;
    text-decoration: none!important;
    width: 0;
    margin-left: 20px;
    margin-right: 20px;
}
.page-link:hover{
    text-decoration: underline!important;
}

.page-link a{
    text-decoration: none!important;
    bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
}
</style>
