<template>
  <div >
    <h1 class="alpha-header">Alpha Results</h1>
    <div >
      <v-container>
        <v-row class="pa-0"  no-gutters>
          <v-col class="pa-0" cols="5" offset="1">
            <div class="pa-0 alpha-panel">
              <div>
                The <b>alpha</b> value for your potential investment is <br/>
                <h1 class="margin-top-ten big-alpha">{{formattedAlpha}}</h1>
                <div class="investment-indicator">
                  <img :src="selectedImage" :alt="imageAlt" />
                </div>
              </div>
              <!-- <h1>Alpha : {{this.alphaResults.alpha}}</h1> -->
            </div>
          </v-col>
          <v-col class="pa-0" cols="5" >
            <div class="pa-0 results-panel">
              <div class="summary-box">
                <h2>Summary</h2>
                <div class="summary-grid">
                  <div class="summary-item">
                    <span class="label">Area</span>
                    <span class="value">{{ this.neighborhood }}</span>
                  </div>
                  <div class="result-display">
                    <!-- <h2>Alpha: {{ formattedAlpha }}</h2> -->
                  </div>
                  <div class="summary-item">
                    <span class="label">Average rent in the Area</span>
                    <span class="value">$ {{ this.formatNumber(this.alphaResults.avg_rent)}}</span>
                  </div>
                  <div class="summary-item">
                    <span class="label">Average price of properties in the Area</span>
                    <!-- formatNumber(this.alphaResults.avg_rent) -->
                    <span class="value">$ {{ this.formatNumber(this.alphaResults.avg_property) }}</span> 
                  </div>
                  <div class="summary-item">
                    <span class="label">Volatility rate</span>
                    <span class="value">{{ formattedVolatility }} %</span>
                    <!-- <span class="value">{{ this.alphaResults.beta }}%</span> -->
                  </div>
                  <div class="summary-item">
                    <span class="label">Market Sample Size</span>
                    <span class="value">{{ this.alphaResults.market_sample_size.toLocaleString() }} <span style="font-style: normal; font-weight: 400;">Units</span></span>
                  </div>
                  <router-link to="/" class="page-link"  style="width: 100%!important">Back to Calculator</router-link>
                </div>
              </div>
<!-- 
              <div class="results-grid">
                <div class="result-box">
                  <h3>Your {{ paymentFrequency.toLowerCase() }} payment</h3>
                  <span class="large-value">${{ calculatePayment().toFixed(2) }}</span>
                </div>
                <div class="result-box">
                  <h3>Total interest paid</h3>
                  <span class="large-value">${{ calculateTotalInterest().toLocaleString() }}</span>
                </div>
              </div> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ShowAlpha',
  props: {
    // alpha: Number,
    alphaResults: {
      alpha: Number,
      beta: Number,
      avg_rent: Number,
      avg_property_price: Number,
      market_sample_size: Number,
      area: String,
      required: false
    },
    neighborhood: String
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
    },
  },
  computed: {
  //   formattedAlpha() {
  //     var alpha = this.alphaResults.alpha
  //     return new Intl.NumberFormat('en-US').format(alpha);
  //   },
    formattedAlpha() {
      const alpha = Number(this.alphaResults.alpha);
      return isNaN(alpha) ? '' : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
        useGrouping: true
      }).format(alpha);
    },
    formattedVolatility() {
      const beta = Number(this.alphaResults.beta) * 100;
      return isNaN(beta) ? '' : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
        useGrouping: true
      }).format(beta);
    },
    selectedImage() {
      var alpha = this.alphaResults.alpha;
      if (alpha >= 0.8 && alpha <= 1.2) {
        return require('@/assets/mid.png');
      } else if (alpha > 1.2) {
        return require('@/assets/up.png');
      } else {
        return require('@/assets/down.png');
      }
    },
    imageAlt() {
      if (this.alpha >= 0.8 && this.alpha <= 1.2) {
        return 'Neutral investment';
      } else if (this.alpha > 1.2) {
        return 'Good investment';
      } else {
        return 'Poor investment';
      }
    }
  },
  // created() {
  //   if (!this.$store.state.isAuthenticated) {
  //     this.$router.push('/login');
  // }
// }
};
</script>

<!--
<style>
.result-display {
  text-align: center;
  margin-top: 20px;
}
</style> -->
<style scoped>
.mortgage-results {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2, h3 {
  color: #003366;
}

.summary-box {
  /* background-color: #f0f0f0; */
  /* border-radius: 8px; */
  padding: 20px;
  /* margin-bottom: 20px; */
  border: 1px solid #c4c8cc;
  padding-bottom: 280px;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(3);
  gap: 10px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
}

.label {
  font-weight: bold;
  color: #000000;
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.result-box {
  background-color: #e6f2ff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.large-value {
  font-size: 24px;
  font-weight: bold;
  color: #003366;
}

.chart-section, .amortization-schedule {
  margin-top: 40px;
}
.alpha-panel {
  background-color: #006AC3;
  border: 1px solid #c4c8cc;
  height: 100%;
  /* padding-right: 0px!important; */
  color: #fff;
  display: flex;
  align-items: center; /* Vertically centers the text */
  justify-content: center;
}
.alpha-panel h1{
  color: #fff;
}
.results-pane {
  border: 4px solid #c4c8cc!important;
  /* padding-left: 0px!important; */
}
.margin-top-ten {
  margin-top: 10px;
}
.investment-indicator img {
  margin-top: 10px;
  width: 70px; 
  height: auto;
}
.big-alpha h1 {
  font-size: 30px;
}

.alpha-header {
  margin-top: 60px!important;
  margin-bottom: 40px;
  padding-bottom: 10px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  border-bottom: 1px solid #c4c8cc;
  letter-spacing: 7px;
  font-weight: lighter;
}
.page-link {
    letter-spacing: 4px; /* Adjust the value as needed */
    margin-top: 40px!important;
    margin-bottom: 10px;
    font-weight: lighter;
    font-size: 22px!important;
    color: #006AC3;
    text-decoration: none!important;
}
.page-link:hover{
    text-decoration: underline!important;
}
</style>
