<template>
  <div>
    <div :style="{marginTop: '15px'}" class="page-header">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="2">
            <div class="title-panel-left">
              <router-link to="/" class="page-link">Calculator</router-link>
            </div>
          </v-col>
          <v-col cols="12" sm="8" >
            <h1 class="page-title">How To Use the Calculator</h1>
          </v-col>
          <v-col cols="12" sm="2" >
            <div class="title-panel-right">
              <router-link to="/summary" class="page-link">Summary</router-link>
            </div>
          </v-col>
         </v-row>
      </v-container>
    </div>
    <div class="Introduction">
      <div class="welcome">Welcome</div>
      If you’re here, it’s likely because you want to learn more about how the alpha calculator works or you might have clicked the wrong link. Either way, this guide provides a detailed, step-by-step explanation on how to use the <span class="alpha-calculator">Alpha Calculator</span>.
    </div>
    <div class="howto">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 1</span>Finding a property</h3>
              <div class="explanation-details">
                The first steps of use the calcultor does not start here but rather on your go-to property sales listing platform. Find your property, estimate the yearly potential revenues and costs of operating it and then come back to the calculator.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image" :src="this.stepImage(1)" alt="step1">
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 2</span>The Calculator</h3>
              <div class="explanation-details">
                Once you are ready to start, head to calculator page (<b>you can use the <router-link to="/" class="text-link">calculator</router-link> link in the top left of his page</b>) and you should be greeted with a page very similar the one shown on the right of this panel.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image" :src="this.stepImage(2)" alt="step2">
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 3</span>Main Panel (Left Panel)</h3>
              <div class="explanation-details">
                You can start anywhere you want but if you want to start in the <b>main panel</b>, the picture on the right shows how it should look like. Head to <b>step 4</b> and <b>step 5</b> for more details.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image" :src="this.stepImage(3)" alt="step3">
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 4</span>General Information</h3>
              <div class="explanation-details">
                For the <b>risk free rate</b>, by default we are using <b>4.72 %</b> as it was the Canadian key rate at the time of this writting, however you can always go to the official website of the <a class="text-link" href="https://www.bankofcanada.ca/rates/interest-rates/key-interest-rates/" target="_blank"><b>Bank of Canada</b></a> to adjust it or set it to whatever makes sense for your situation.
                <br>
                <br>
                Additionally, you may or may not have details on the individual characteristics or every single renting unit (<i>no of rooms, no of bathrooms and area</i>) of your target property. If you don't, it does not matter. We will make an estimate of the units, depending on the trends in the specific market you are looking at, to make our predictions. However if you do have those details, you can simple simply check the <i><b>"Has Renting Unit Details"</b></i> box and enter the details in the <b>Renting Units</b> panel. More on that in <b>step 8</b> and <b>step 9</b>.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image" :src="this.stepImage(4)" alt="step4">
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 5</span>Property Details : Required Fields</h3>
              <div class="explanation-details">
                The following fields are required : <b>Neighborhood</b>, <b>Property Type</b>, <b>Purchase Price</b>, <b>Potential Gross Revenues</b> and <b>Operation Cost</b>.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image" :src="this.stepImage(5)" alt="step5">
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 6</span>Property Details : Everything else</h3>
              <div class="explanation-details">
                For multi-units properties, you can specify the type of unit each property belongs to. For Condos and Houses, when the calculation is done the total unit is set to 1.
                If your property is under construction or has a special label linked to its age <i>("Historic" for instance) </i>, you can specify it in the <i><b>"Age Status"</b></i> box.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image" :src="this.stepImage(6)" alt="step6">
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 7</span>More Features</h3>
              <div class="explanation-details">
                Here you can give additional context to our calculator regarding the features of your property that may affect its value. The features are grouped in sub categories to help you quickly find what you need to adjust.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image" :src="this.stepImage(7)" alt="step7">
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 8</span>Renting Units</h3>
              <div class="explanation-details">
                As it was mentioned earlier, you may want to specify the configuration of each unit in your property. If this is your case, you can simply enter the amount of rooms, bathrooms and the area of your property in the <b>Renting Units</b> panel. Make sure the check the <i><b>"Has Renting Unit Details"</b></i> under <b>Main Panel</b> → <b>General Information Units</b>.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image" :src="this.stepImage(8)" alt="step8">
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 9</span>Renting Units : Add aand Remove</h3>
              <div class="explanation-details">
                To add more units, press the <i><b>"Add Unit"</b></i> button. If you added more units that you need, just press the <i><b>"x"</b></i> button on the top right of the unit window you want to remove.
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image" :src="this.stepImage(9)" alt="step9">
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 10</span>Find Alpha!</h3>
              <div class="explanation-details">
                Once you are ready, press the submit button at the bottom of the page and wait!
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image alpha" :src="this.stepImage(10)" alt="step10">
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="explanation">
              <h3 class="explanation-title"><span class="step">Step 11</span>Alpha Results</h3>
              <div class="explanation-details">
                After <b>Alpha</b> is computed, you will land on the <b>Alpha Results</b> page and will be met with your value, a couple of statistics to give you some context. The <i><b>Market Sample size</b></i> row give you the amount of the similar properties in our database used to represent the market your property is in. This should give you an idea of how accurate the alpha is.
              <br/>
              <br/>
              For a detailed explanation on interpreting the <b>Alpha</b> value, please refer to the <a class="text-link" href="/summary"><b>Summary tab</b></a>.
              
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="image-panel">
              <img class="image" :src="this.stepImage(11)" alt="step11">
            </div>
          </v-col>
         </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowTo',
  methods: {
    stepImage(step) {
      return require('@/assets/howto/step' + step + '.jpg');
    },
  },
  // created() {
  //   if (!this.$store.state.isAuthenticated) {
  //     this.$router.push('/login');
  //   }
  // }
};
</script>

<style>
.page-title {
    letter-spacing: 7px;
    font-weight: lighter;
}
.page-link {
    letter-spacing: 4px; /* Adjust the value as needed */
    margin-top: 40px!important;
    margin-bottom: 10px;
    font-weight: lighter;
    font-size: 22px!important;
    color: #006AC3;
    text-decoration: none!important;
    width: 0;
    margin-left: 20px;
    margin-right: 20px;
}
.page-link:hover{
    text-decoration: underline!important;
}

.page-link a{
    text-decoration: none!important;
    bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
}

.text-link {
    color: #006AC3;
    text-decoration: none!important;
}
.text-link:hover{
    text-decoration: underline!important;
}

.text-link a{
    text-decoration: none!important;
    bottom: 0;
}

.title-panel-right {
  text-align: justify;
  font-size: 2em;
  border-left: 1px solid #c4c8cc;
  padding-left: 15px;
}

.title-panel-left {
  text-align: justify;
  font-size: 2em;
  border-right: 1px solid #c4c8cc;
  padding-right: 15px;
}

.howto {
  margin-left: 10%;
  margin-right: 10%;
}

.explanation {
  margin-top: 50px;
  /* border: 0px solid #3271a8; */
  border: 1px solid #3271a8;
  /* border: 2px solid #3271a8; */
  text-align: left;
}

.explanation-details {
  text-align: justify;
  padding: 5px 20px 20px 10px;
}

.step {
  margin-left: 5px;
  margin-right: 5px;
  letter-spacing: 2px;
  color: #006AC3;
  font-weight: 700!important;
}

.explanation-title {
  margin-top: 5px;
  font-weight: 600;
}

.image {
  width: 39vw;
  padding-left: 1vw;
}

.alpha {
  width: 150px;
}

.image-panel {
  margin-bottom: 40px;
}
.Introduction {
  font-size: 19px;
  margin-left: 15%;
  margin-right: 15%;
  /* margin-left: 10%;
  margin-right: 10%; */
  margin-top: 50px;
  margin-bottom: 40px;
}
.alpha-calculator{
  font-weight: 500;
  font-family: Ubuntu;
  color: #006AC3;
}
.welcome {
  font-weight: 600;
  font-size: 21px;
  letter-spacing: 2px;
  margin-bottom: 11px;
}
.page-header {
  min-width: 1070px!important;
}
</style>
