<template>
  <div class="login">
    <br/>
    <h2>Login</h2>
    <br/>
    <form @submit.prevent="login">
    <br/>
      <input class="input" v-model="username" type="text" placeholder="Username" required>
      <br/>
      <input class="input" v-model="password" type="password" placeholder="Password" required>
      <br/>
      <button class="button" type="submit">Login</button>
      <br/>
    </form>
  </div>
</template>

<script>
export default {
name: 'LogIn',
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
        async login() {
        try {
            const loginSuccess = await this.$store.dispatch('login', {
            username: this.username,
            password: this.password
            });
            if (loginSuccess) {
                console.log("Login successful, checking session...");
                // const sessionValid = await this.$store.dispatch('checkSession');
                // console.log("Session check result:", sessionValid);
            // if (sessionValid) {
                this.$router.push('/');
            // } else {
            //     console.error("Session invalid after successful login");
            // }
            } else {
            console.error("Login failed");
            }
        } catch (error) {
            console.error("Login error:", error);
        }
        }
    // async login() {
    //   const success = await this.$store.dispatch('login', {
    //     username: this.username,
    //     password: this.password
    //   })
    //   console.log(success)
    //   if (success) {
    //     this.$router.push('/')
    //   } else {
    //     alert('Login failed')
    //   }
    // }
  }
}
</script>

<style scoped>
.input {
  border: 0.5px solid #a1b7d0;
  margin: auto;
  margin-bottom: 25px;
  border-radius: 1px;
  text-align: left;
  padding: 10px;
  width: auto;
  font-size: 16px!important;
}
.input:hover {
  border: 0.6px solid #5c7a9e;
  margin: auto;
  margin-bottom: 25px;
  border-radius: 1px;
  text-align: left;
  padding: 10px;
  width: auto;
  font-size: 16px!important;
}
.button {
  /* background-color: #1a5baa; */
  color: #fff;
  font-weight: bold!important;
  padding: 15px 35px;
  background-color: #0051a5;
  -webkit-box-shadow: inset 0 0 0 1px #003168;
  box-shadow: inset 0 0 0 1px #003168;
}
.button:hover {
  /* background-color: #1a5baa; */
  color: #fff;
  font-weight: bold!important;
  padding: 15px 35px;
  background-color: #003168;
  -webkit-box-shadow: inset 0 0 0 1px #003168;
  box-shadow: inset 0 0 0 1px #003168;
}
</style>