<template>
  <div class="real-estate-alpha">
    <CalculatorForm />
  </div>
</template>

<script>
import CalculatorForm from './CalculatorForm.vue';

export default {
  components: {
    CalculatorForm,
  },
};
</script>

<style>
.rea {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>
