<template>
  <div id="app">
    <TopHeader />
    <router-view></router-view>
  </div>
</template>

<script>
import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/400-italic.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import TopHeader from './components/TopHeader.vue';

export default {
  name: 'App',
  components: {
    TopHeader
  },
  methods: {
    login() {
      this.$router.push('/login');
    },
    async logout() {
      await this.$store.dispatch('logout');
      this.$router.push('/login');
    }
  },
  async created() {
    try {
      // await this.$store.dispatch('checkAuth');
      const isSessionValid = await this.$store.dispatch('checkSession');
      
      console.log('Session check result:', isSessionValid);
      
      if (!isSessionValid && this.$route.meta.requiresAuth) {
        console.log('Session invalid, redirecting to login');
        this.$router.push('/login');
      } else if (isSessionValid) {
        console.log('Session valid, user authenticated');
      }
    } catch (error) {
      console.error('Error during authentication check:', error);
      this.$router.push('/login');
    }
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
.page-header {
    margin-top: 60px!important;
    margin-bottom: 40px;
    width: 65%;
    margin-left: 25%;
    margin-right: 10%;
    border-bottom: 1px solid #c4c8cc;
}
</style>
