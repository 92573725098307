<template>
  <div class="dropdown-style" v-if="options">
    <input 
      :name="name"
      @focus="showOptions()"
      style="border: none; outline: none; margin-bottom: 2px;"
      @blur="exit()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder"
      autocomplete="off"
      class="width-auto"
    />

    <div
      v-show="optionsShown"
      class="drop-content">
      <div
        class="item-drop"
        @mousedown="selectOption(option)"
        autocomplete="off"
        v-for="(option, index) in filteredOptions"
        :key="index">
          {{ getOptionLabel(option) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    value: {
      type: [String, Number, Object],
      default: null
    },
    name: {
      type: String,
      default: 'dropdown'
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Please select an option'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxItem: {
      type: Number,
      default: 9
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    valueKey: {
      type: String,
      default: 'id'
    }
  },
  data() {
    return {
      selected: null,
      optionsShown: false,
      searchFilter: ''
    }
  },
  created() {
    this.initializeSelected();
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'ig');
      for (const option of this.options) {
        const label = this.getOptionLabel(option);
        if (this.searchFilter.length < 1 || label.match(regOption)){
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    }
  },
  methods: {
    initializeSelected() {
      if (this.value !== null && this.value !== undefined) {
        const selectedOption = this.options.find(option => 
          this.getOptionValue(option) === this.value || 
          this.getOptionLabel(option) === this.value
        );
        if (selectedOption) {
          this.selectOption(selectedOption, false);
        }
      }
    },
    selectOption(option, shouldEmit = true) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.getOptionLabel(this.selected);
      if (shouldEmit) {
        this.$emit('input', this.getOptionValue(this.selected));
        this.$emit('selected', this.selected);
      }
    },
    showOptions(){
      if (!this.disabled) {
        this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected) {
        this.selected = null;
        this.searchFilter = '';
      } else {
        this.searchFilter = this.getOptionLabel(this.selected);
      }
      this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    keyMonitor(event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    },
    getOptionLabel(option) {
      if (typeof option === 'object' && option !== null) {
        return option[this.labelKey] || option.name || option.label || '-';
      }
      return String(option);
    },
    getOptionValue(option) {
      if (typeof option === 'object' && option !== null) {
        return option[this.valueKey] || option.id || option.value;
      }
      return option;
    }
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = null;
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit('filter', this.searchFilter);
    },
    value: {
      handler() {
        this.initializeSelected();
      },
      immediate: true
    }
  }
};
</script>

<style lang="css" scoped>
  /* .dropdown {
    position: relative;
    display: block;
    margin: auto;
    .dropdown-input {
      background: #fff;
      cursor: pointer;
      border: 1px solid #e7ecf5;
      border-radius: 3px;
      color: #333;
      display: block;
      font-size: .8em;
      padding: 6px;
      min-width: 150px;
      max-width: 250px;
      &:hover {
        background: #f8f8fa;
      }
    }
    .dropdown-content {
      position: absolute;
      background-color: #fff;
      min-width: 248px;
      max-width: 248px;
      max-height: 248px;
      border: 1px solid #e7ecf5;
      box-shadow: 0px -8px 34px 0px rgba(0,0,0,0.05);
      overflow: auto;
      z-index: 1;
      .dropdown-item {
        color: black;
        font-size: .7em;
        line-height: 1em;
        padding: 8px;
        text-decoration: none;
        display: block;
        cursor: pointer;
        &:hover {
          background-color: #e7ecf5;
        }
      }
    }
    .dropdown:hover .dropdowncontent {
      display: block;
    }
  } */
  .drop-content {
    z-index: 3;
    position: relative;
    display: block;
    /* position: absolute; */
    background-color: #fff;
    overflow: auto;
  }
  .item-drop:hover {
            background-color: #006AC3; /* Background color on hover */
            color: #ffffff; /* Font color on hover */
        }
  .item-drop {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 3px;
        }

.width-auto {
    width: 100%;
}
</style>