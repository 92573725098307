<template>
  <div class="montreal-map">
    <svg :viewBox="viewBox">
      <g v-for="neighborhood in matchedNeighborhoods" :key="neighborhood.id">
        <path
          v-if="neighborhood.geometry"
          :d="pathForFeature(neighborhood)"
          :fill="getColor(neighborhood.name)"
          @mouseover="showTooltip(neighborhood)"
          @mouseout="hideTooltip"
        />
        <text
          v-if="neighborhood.geometry"
          :x="centroid(neighborhood)[0]"
          :y="centroid(neighborhood)[1]"
          font-size="8"
          text-anchor="middle"
        >
          {{ neighborhood.id }}
        </text>
      </g>
      <text
        v-if="activeNeighborhood"
        :x="tooltipX"
        :y="tooltipY"
        fill="black"
        font-size="3"
      >
        {{ activeNeighborhood.name }}: {{ activeNeighborhood.data }}
      </text>
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  data() {
    return {
      neighborhoods : [
        {
          name: 'Pierrefonds',
          id: 'Pierrefonds',
          rentalMedianPrice: 1250.0,
          rentalAveragePrice: 1565.888889,
          propertyMedianPrice: 599000,
          propertyAveragePrice: 688436.7
        },
        // {
        //   name: 'Ahuntsic-Cartierville',
        //   id: 'Mount Royal',
        //   rentalMedianPrice: 1600.0,
        //   rentalAveragePrice: 1680.975904,
        //   propertyMedianPrice: 799000,
        //   propertyAveragePrice: 985874.2
        // },
        {
          name: 'Ville-Marie',
          id: 'Downtown',
          rentalMedianPrice: 1525.0,
          rentalAveragePrice: 1656.439325,
          propertyMedianPrice: 549000,
          propertyAveragePrice: 777261.1
        },
        // {
        //   name: 'Villeray',
        //   id: 'Villeray',
        //   rentalMedianPrice: 1400.0,
        //   rentalAveragePrice: 1507.279182,
        //   propertyMedianPrice: 730000,
        //   propertyAveragePrice: 784354.4
        // },
        // {
        //   name: 'Villeray',
        //   id: 'Mile-End',
        //   rentalMedianPrice: 1400.0,
        //   rentalAveragePrice: 1507.279182,
        //   propertyMedianPrice: 747000,
        //   propertyAveragePrice: 950940.50
        // },
        {
          name: 'Villeray',
          id: 'Villeray/Mile-End',
          rentalMedianPrice: 1400.0,
          rentalAveragePrice: 1507.279182,
          propertyMedianPrice: 738500,
          propertyAveragePrice: 867647.45
        },
        {
          name: 'Rosemont',
          id: 'Rosemont',
          rentalMedianPrice: 1350.0,
          rentalAveragePrice: 1450.454008,
          propertyMedianPrice: 725000,
          propertyAveragePrice: 778202.7
        },
        {
          name: 'Sud-Ouest',
          id: 'Griffintown',
          rentalMedianPrice: 1650.0,
          rentalAveragePrice: 1740.761128,
          propertyMedianPrice: 508000,
          propertyAveragePrice: 622031.7
        },
        {
          name: 'LaSalle',
          id: 'LaSalle',
          rentalMedianPrice: 1400.0,
          rentalAveragePrice: 1478.820000,
          propertyMedianPrice: 689000,
          propertyAveragePrice: 707471.4
        },
        {
          name: 'Pierrefonds--Roxboro',
          id: 'Pointe-Claire',
          rentalMedianPrice: 1874.5,
          rentalAveragePrice: 1822.000000,
          propertyMedianPrice: 848000,
          propertyAveragePrice: 963587.4
        },
        // {
        //   name: 'Pointe-aux-Trembles-Rivieres-des-Prairies',
        //   id: 'Pointe-aux-Trembles',
        //   rentalMedianPrice: 1380.0,
        //   rentalAveragePrice: 1366.680851,
        //   propertyMedianPrice: 425000,
        //   propertyAveragePrice: 511356.4
        // },
        // {
        //   name: 'Pointe-aux-Trembles-Rivieres-des-Prairies',
        //   id: 'Riviere-des-Prairies',
        //   rentalMedianPrice: 1400.0,
        //   rentalAveragePrice: 1470.615385,
        //   propertyMedianPrice: 519800,
        //   propertyAveragePrice: 583211.1
        // },
        {
          name: 'Pointe-aux-Trembles-Rivieres-des-Prairies',
          id: 'Pointe-aux-Trembles-Rivieres-des-Prairies',
          rentalMedianPrice: 1390.0,
          rentalAveragePrice: 1418.65,
          propertyMedianPrice: 472400,
          propertyAveragePrice: 547283.75
        },
        {
          name: 'Anjou',
          id: 'Anjou',
          rentalMedianPrice: 1400.0,
          rentalAveragePrice: 1404.783951,
          propertyMedianPrice: 574900,
          propertyAveragePrice: 605095.4
        },
        // {
        //   name: 'Ahuntsic',
        //   id: 'Ahuntsic',
        //   rentalMedianPrice: 1400.0,
        //   rentalAveragePrice: 1417.958716,
        //   propertyMedianPrice: 679000,
        //   propertyAveragePrice: 721648.7
        // },
        // {
        //   name: 'Cartierville',
        //   id: 'Cartierville',
        //   rentalMedianPrice: 1200.0,
        //   rentalAveragePrice: 1363.930233,
        //   propertyMedianPrice: 639000,
        //   propertyAveragePrice: 770669.2
        // },
        {
          name: 'Ahuntsic-Cartierville',
          id: 'Ahuntsic-Cartierville',
          rentalMedianPrice: 1300.0,
          rentalAveragePrice: 1390.94,
          propertyMedianPrice: 659000,
          propertyAveragePrice: 746158.95
        },
        {
          name: 'Saint-Laurent',
          id: 'Saint-Laurent',
          rentalMedianPrice: 1454.0,
          rentalAveragePrice: 1540.065060,
          propertyMedianPrice: 649000,
          propertyAveragePrice: 754067.3
        },
        {
          name: 'St-Leonard',
          id: 'Saint-Leonard',
          rentalMedianPrice: 1199.0,
          rentalAveragePrice: 1296.607843,
          propertyMedianPrice: 799900,
          propertyAveragePrice: 833919.1
        },
        // {
        //   name: 'Notre-Dame-de-Grace',
        //   id: 'Notre-Dame-de-Grace',
        //   rentalMedianPrice: 1550.0,
        //   rentalAveragePrice: 1590.358663,
        //   propertyMedianPrice: 774450,
        //   propertyAveragePrice: 880890.5
        // },
        // {
        //   name: 'Cote-des-Neiges',
        //   id: 'Cote-St-Luc',
        //   rentalMedianPrice: 1449.0,
        //   rentalAveragePrice: 1480.889088,
        //   propertyMedianPrice: 859000,
        //   propertyAveragePrice: 842600.0
        // },
        // {
        //   name: 'Cote-des-Neiges',
        //   id: 'Cote-des-Neiges',
        //   rentalMedianPrice: 1399.0,
        //   rentalAveragePrice: 1517.972571,
        //   propertyMedianPrice: 649000,
        //   propertyAveragePrice: 815859.7
        // },
        // {
        //   name: 'Cote-des-Neiges--Notre-Dame-de-Grace',
        //   id: 'Cote-des-Neiges--Notre-Dame-de-Grace',
        //   rentalMedianPrice: 1550.0,
        //   rentalAveragePrice: 1590.358663,
        //   propertyMedianPrice: 774450,
        //   propertyAveragePrice: 880890.5
        // },
        {
          name: 'Cote-des-Neiges--Notre-Dame-de-Grace',
          id: 'Cote-des-Neiges--Notre-Dame-de-Grace-Cote-St-Luc',
          rentalMedianPrice: 1466.0,
          rentalAveragePrice: 1529.740107,
          propertyMedianPrice: 760816.67,
          propertyAveragePrice: 846450.07
        },
        {
          name: 'Outremont',
          id: 'Outremont',
          rentalMedianPrice: 1700.0,
          rentalAveragePrice: 1866.566406,
          propertyMedianPrice: 1100000,
          propertyAveragePrice: 1526859.0
        },
        // {
        //   name: 'Outremont',
        //   id: 'Westmount',
        //   rentalMedianPrice: 1630.0,
        //   rentalAveragePrice: 1759.808511,
        //   propertyMedianPrice: 950000,
        //   propertyAveragePrice: 1077857.0
        // },
        {
          name: 'Montreal-Nord',
          id: 'Montreal-Nord',
          rentalMedianPrice: 980.0,
          rentalAveragePrice: 1098.339064,
          propertyMedianPrice: 525000,
          propertyAveragePrice: 579591.1
        },
        // {
        //   name: "Ile-des-Soeurs",
        //   id: "L'Ile-Des-Soeurs",
        //   rentalMedianPrice: 1770.5,
        //   rentalAveragePrice: 1948.350000,
        //   propertyMedianPrice: 748000,
        //   propertyAveragePrice: 938610.2
        // },
        // {
        //   name: 'Verdun',
        //   id: 'Verdun',
        //   rentalMedianPrice: 1650.0,
        //   rentalAveragePrice: 1799.412698,
        //   propertyMedianPrice: 725000,
        //   propertyAveragePrice: 787254.6
        // },
        {
          name: "Verdun--Ile-des-Soeurs",
          id: "L'Ile-Des-Soeurs/Verdun",
          rentalMedianPrice: 1710.25,
          rentalAveragePrice: 1873.881349,
          propertyMedianPrice: 736500,
          propertyAveragePrice: 862932.4
        },
        {
          name: 'Ville-Marie',
          id: 'Old Montreal',
          rentalMedianPrice: 1790.0,
          rentalAveragePrice: 1908.714286,
          propertyMedianPrice: 749000,
          propertyAveragePrice: 970038.5
        },
        // {
        //   name: 'Maisonneuve',
        //   id: 'Maisonneuve',
        //   rentalMedianPrice: 1495.0,
        //   rentalAveragePrice: 1529.182482,
        //   propertyMedianPrice: 529000,
        //   propertyAveragePrice: 642745.6
        // },
        // {
        //   name: 'Mercier',
        //   id: 'Mercier',
        //   rentalMedianPrice: 1350.0,
        //   rentalAveragePrice: 1393.450172,
        //   propertyMedianPrice: 558000,
        //   propertyAveragePrice: 582600.2
        // },
        // {
        //   name: 'Hochelaga',
        //   id: 'Hochelaga',
        //   rentalMedianPrice: 1380.0,
        //   rentalAveragePrice: 1392.011136,
        //   propertyMedianPrice: 499900,
        //   propertyAveragePrice: 682186.5
        // },
        {
          name: "Mercier-Hochelaga-Maisonneuve",
          id: "Maisonneuve / Mercier / Hochelaga",
          rentalMedianPrice: 1408.33,
          rentalAveragePrice: 1438.21,
          propertyMedianPrice: 528966.67,
          propertyAveragePrice: 635844.1
        },
        {
          name: 'Lachine',
          id: 'Lachine',
          rentalMedianPrice: 1450.0,
          rentalAveragePrice: 1492.789474,
          propertyMedianPrice: 595000,
          propertyAveragePrice: 623520.4
        },
        {
          name: "L'Ile-Bizard",
          id: "L'Ile-Bizard",
          rentalMedianPrice: 1500.0,
          rentalAveragePrice: 1573.333333,
          propertyMedianPrice: 825000,
          propertyAveragePrice: 1089686.0
        },
        {
          name: 'Saint-Michel',
          id: 'Saint-Michel',
          rentalMedianPrice: 1300.0,
          rentalAveragePrice: 1284.151807,
          propertyMedianPrice: 645000,
          propertyAveragePrice: 658229.8
        },
        {
          name: 'Parc-Extension',
          id: 'Parc-Extension',
          rentalMedianPrice: 1250.0,
          rentalAveragePrice: 1293.729730,
          propertyMedianPrice: 695000,
          propertyAveragePrice: 702955.3
        },
        // {
        //   name: 'Mercier',
        //   id: 'Montreal East',
        //   rentalMedianPrice: 1050.0,
        //   rentalAveragePrice: 1112.529617,
        //   propertyMedianPrice: 495000,
        //   propertyAveragePrice: 635380.0
        // },
        {
          name: 'Sud-Ouest',
          id: 'Saint-Henri',
          rentalMedianPrice: 1700.0,
          rentalAveragePrice: 1750.290441,
          propertyMedianPrice: 599000,
          propertyAveragePrice: 742463.3
        },
        {
          name: 'Sud-Ouest',
          id: 'Pointe-Saint-Charles',
          rentalMedianPrice: 1700.0,
          rentalAveragePrice: 1772.994118,
          propertyMedianPrice: 725000,
          propertyAveragePrice: 869170.5
        },
        {
          name: 'Sainte-Genevieve',
          id: 'Sainte-Genevieve',
          rentalMedianPrice: 1525.0,
          rentalAveragePrice: 1631.875000,
          propertyMedianPrice: 539000,
          propertyAveragePrice: 588704.9
        },
        {
          name: 'Plateau-Mont-Royal',
          id: 'Plateau Mont-Royal',
          rentalMedianPrice: 1579.5,
          rentalAveragePrice: 1665.085770,
          propertyMedianPrice: 609000,
          propertyAveragePrice: 871349.9
        },
      ],
      geoJsonFeatures: [],
      matchedNeighborhoods: [],
      activeNeighborhood: null,
      tooltipX: 0,
      tooltipY: 0,
      viewBox: '0 0 100 100',
      projection: null,
      path: null,
    }
  },
  mounted() {
    this.loadGeoJson();
  },
  methods: {
    loadGeoJson() {
      try {
        const geoJsonData = require('@/assets/montreal.json');
        // console.log(this.geoJsonFeatures);
        this.geoJsonFeatures = geoJsonData.features;
        this.matchNeighborhoods();
        this.setupProjection();
        console.log(this.matchedNeighborhoods);
      } catch (error) {
        console.error('Error loading GeoJSON:', error);
      }
    },
    matchNeighborhoods() {
      this.matchedNeighborhoods = this.neighborhoods.map(neighborhood => {
        const match = this.geoJsonFeatures.find(feature => 
          feature.properties.name.toLowerCase().includes(neighborhood.name.toLowerCase())
        //   feature.properties.name.toLowerCase() === neighborhood.name.toLowerCase()
        );
        if (! match) {
            console.log(neighborhood.name);
        }
        return {
          ...neighborhood,
          geometry: match ? match.geometry : null
        };
      });
    },
    setupProjection() {
      const features = this.matchedNeighborhoods.filter(n => n.geometry);
      const bounds = d3.geoBounds({ type: 'FeatureCollection', features });
      const center = d3.geoCentroid({ type: 'FeatureCollection', features });
      console.log('Bounds:', bounds);
      console.log('Center:', center);

      
      this.projection = d3.geoMercator()
        .center(center)
        .fitSize([500, 500], { type: 'FeatureCollection', features });
      
      this.path = d3.geoPath().projection(this.projection);
      console.log('Path for first feature:', this.pathForFeature(this.matchedNeighborhoods[0].geometry));

      
    //   const [[x0, y0], [x1, y1]] = bounds;
    //   this.viewBox = `${x0} ${y0} ${x1 - x0} ${y1 - y0}`;
    this.viewBox = `0 0 500 500`;
      console.log(this.viewBox);
    },
    pathForFeature(feature) {
        const pathData = this.path(feature.geometry);
        // console.log('Path data:', pathData);
        return pathData;
    },
    centroid(feature) {
        if (!feature || !feature.geometry) {
            console.warn('Invalid feature geometry:', feature);
            return [0, 0]; // Fallback coordinates if geometry is missing
        }
        
        const centroid = this.path.centroid(feature.geometry);
        // console.log('Centroid for first feature:', this.centroid(this.matchedNeighborhoods[0]));

        
        if (isNaN(centroid[0]) || isNaN(centroid[1])) {
            console.warn('Centroid calculation resulted in NaN for feature:', feature);
            return [0, 0]; // Fallback coordinates in case of NaN
        }
        
        return centroid;
    },
    getColor(neighborhoodName) {
      const hue = (neighborhoodName.charCodeAt(0) * 10) % 360;
      return `hsl(${hue}, 70%, 80%)`;
    },
    showTooltip(neighborhood) {
        this.activeNeighborhood = {
            name: neighborhood.name,
            rent: 'Median Renting Price ' + neighborhood.rentalMedianPrice,
            property: 'Median Property Price ' + neighborhood.propertyMedianPrice,
        };
        
        if (neighborhood.geometry) {
            const [x, y] = this.centroid(neighborhood.geometry);
            
            // Check if the centroid is valid
            if (!isNaN(x) && !isNaN(y)) {
            this.tooltipX = x;
            this.tooltipY = y - 5; // Offset the tooltip a bit upwards
            } else {
            console.warn('Invalid tooltip position for:', neighborhood.geometry);
            }
        }
    },
    hideTooltip() {
      this.activeNeighborhood = null;
    }
  },
  // created() {
  //   if (!this.$store.state.isAuthenticated) {
  //     this.$router.push('/login');
  //   }
  // }
}
</script>

<style scoped>
.montreal-map {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
svg {
  width: 100%;
  height: auto;
}
path {
  stroke: None;
  /* stroke: #000; */
  stroke-width: 1;
  /* fill: #000; */
}
path:hover {
  fill: rgb(0, 85, 255); /* Highlight on hover */
  opacity: 0.8;
}
</style>